import { GENERAL_URLS } from '@shared/constants/urls';

import {
  arbitrum,
  aurora,
  avalanche,
  base,
  bsc,
  fantom,
  gnosis,
  klaytn,
  mainnet,
  optimism,
  polygon,
  zkSync,
} from '@reown/appkit/networks';
import { CoreTypes } from '@walletconnect/types';

export const networks = [
  arbitrum,
  aurora,
  avalanche,
  base,
  bsc,
  fantom,
  gnosis,
  klaytn,
  mainnet,
  optimism,
  polygon,
  zkSync,
];

const metadataConfig = {
  'store-web': {
    name: 'Magic Store',
    description: 'Crypto App Store by Magic Square',
    url: 'https://magic.store',
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
  staking: {
    name: 'Magic Staking',
    description: 'Crypto App Store by Magic Square',
    url: GENERAL_URLS.staking,
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
  launchpad: {
    name: 'Magic Launchpad',
    description: 'Crypto App Store by Magic Square',
    url: 'https://launchpad.magicsquare.io',
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
  empty: {
    name: 'Magic Square',
    description: 'Magic Square',
    url: 'https://magicsquare.io',
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
} as const satisfies Record<string, CoreTypes.Metadata>;

export const PROJECT_NAME =
  typeof process !== 'undefined'
    ? (process.env.NEXT_PUBLIC_PROJECT_NAME as keyof typeof metadataConfig)
    : undefined;

export const metadata =
  (PROJECT_NAME && PROJECT_NAME in metadataConfig && metadataConfig[PROJECT_NAME]) ||
  metadataConfig.empty;
